import React from 'react'
import '../App.css'
import styled from 'styled-components'

const NumText = styled.div`
  display: block;
  margin: 24px 0 8px 0;
  font-weight: 800;
  font-size: 48px;
  text-align: center;
  color: #8c30f5;
`

const DescrText = styled.div`
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 24px;
  text-align: center;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default function statBlock({ number, descr }) {
  return (
    <>
      <Wrapper>
        <NumText>{number}</NumText>
        <DescrText>{descr}</DescrText>
      </Wrapper>
    </>
  )
}
