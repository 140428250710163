import { Light, Power, TextColor, PowerHover } from "../constants";
import styled, { keyframes } from "styled-components";

export const Header = styled.h1`
  width: ${(props) => props.width};
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${(props) => props.marginBottom};
  font-weight: 600;
  font-size: 52px;
  text-align: center;
  line-height: 80px;
  color: ${TextColor};
  display: block;
  @media (max-width: 768px) {
    font-size: 48px;
    width: 80%;
  }
`;

export const Button = styled.a`
  width: 196px;
  padding: 16px 0px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  background: ${Light};
  border-radius: 8px;
  color: #fafafa;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  display: block;
  transition: all 0.5s;
  &:hover {
    background: #1e9da5;
  }
`;

export const QuestionButton = styled.button`
  border: 3px solid ${Power};
  background-color: ${Power};
  padding: 4px 8px;
  margin: 10px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  border-radius: 4px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);

  transition: all 0.2s;
  &:hover {
    border: 3px solid ${PowerHover};
    background-color: ${PowerHover};
    color: #fafafa;
  }
`;
const drop = keyframes`
  0% {
    height: 0px;
    opacity: 0;
  }

  20% {
    height: 40px;
    opacity: 1;
  }

  90% {
    height: 40px;
    opacity: 1;
  }

  100% {
    height: 0px;
    opacity: 0;
    display: none;
  }
`;

export const Banner = styled.div`
  font-size: 16px;
  position: absolute;
  top: 0%;
  width: 100%;
  background-color: ${Power};
  color: #fff;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  animation: ${drop} 3s ease-out;
  opacity: 0;
`;

export const SaveBanner = styled.div`
  position: fixed;
  top: 0%;
  width: 100%;
  background-color: ${Power};
  color: ${TextColor};
  font-size: 18px;
  height: ${(props) => (props.animate ? "40px" : "0px")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  transition: all 2s cubic-bezier(0, 1.01, 0.4, 0.99);
  opacity: ${(props) => (props.animate ? "1" : "0")};
  z-index: 3;
`;

export const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 16px;
  padding: 12px;
  outline: none;
  color: #333;
  font-family: --apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  border: none;
  border-radius: 4px;
  outline: none;
  width: 100%;
  @media (max-width: 768px) {
    width: 80%;
  }
  /* border-bottom: 1px solid pink; */

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #666;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #666;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #666;
  }
`;

export const Select = styled.select`
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 8px;
  margin-right: 12px;
`;

export const Section = styled.section`
  padding: ${(props) => (props.padding ? props.padding : "60px")};
  color: #333;
  width: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fff"};
  height: ${(props) => props.height};
  text-align: ${(props) => props.textAlign};
  display: ${(props) => props.display};
  margin: ${(props) => props.margin};
`;

export const FullpageSection = styled.section`
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Manrope, "sans-serif";
`;

export const OuterWrapper = styled.div`
  color: #111;
  width: 32rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const MyRow = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
`;
export const MyCol = styled.div`
  flex-direction: column;
  flex: ${(props) => props.xl};
  @media (max-width: 992px) {
    flex: ${(props) => props.lg};
  }
  @media (max-width: 768px) {
    flex: ${(props) => props.md};
  }
`;
