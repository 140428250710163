import React, { useState, useEffect } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import Question from "../components/Question";
import Privacy from "../components/Privacy";
import { withRouter } from "react-router-dom";
import SelectBar from "../components/SelectBar";
import Header from "../components/Header";

const Form = ({ history }) => {
  const [data, setData] = useState({
    name: "",
    age: "",
    type: "",
    school: "",
    useCase: "",
    questions: "",
    email: "",
  });
  // eslint-disable-next-line
  const [animate, setAnimate] = useState(false); // for Save Confirmation Banner

  const keys = [
    "name",
    "age",
    "type",
    "school",
    "useCase",
    "questions",
    "email",
  ];

  function bannerUp() {
    setAnimate(false);
  }

  function checkEmail() {
    const spamWords = [
      "test",
      "spam",
      "shit",
      "fuck",
      "bitch",
      "cock",
      "cunt",
      "dick",
      "faggot",
      "ass",
      "titty",
      "titties",
    ];
    const specialChars = ["!", "*", "&", "^", "$", "#"];

    if (!data["email"].toLowerCase().includes("@")) {
      alert("Please enter a valid email address");
      return false;
    }

    let tempEmail = data["email"].toLowerCase().trim();
    let before = tempEmail.substr(0, tempEmail.indexOf("@"));

    let spam = false;
    // eslint-disable-next-line
    spamWords.map((word, index) => {
      if (before === word) {
        spam = true;
      }
    });
    // eslint-disable-next-line
    specialChars.map((char, index) => {
      if (tempEmail.includes(char)) {
        spam = true;
      }
    });

    if (tempEmail.split("@").length - 1 > 1) {
      spam = true;
    }
    if (before.includes(" ") || spam || before.length <= 1) {
      alert("Please enter a valid email");
      return false;
    }
    return true;
  }

  function pushToSheets(anotherClass) {
    if (!checkEmail()) {
      return false;
    }

    // UNCOMMENT to check for all filled in
    for (var i = 0; i < keys.length; i++) {
      if (!data[keys[i]] && i !== 3 && i !== 4 && i !== 5) {
        alert("Please fill in all required fields");
        return false;
      }
    }

    var formData = new FormData();
    for (var key in data) {
      if (key === "email") {
        formData.append(key, data[key].toLowerCase().trim());
      } else {
        formData.append(key, data[key].trim());
      }
    }
    setData({ ...data, name: "", type: "", email: "" });
    fetch(
      "https://script.google.com/macros/s/AKfycbwoaULQC9wi1mimPIqtsfX49AKiVRHPUCwix1XnIpqc7oz9WQiCmURJp9OdB7Anuv2L/exec",
      { method: "POST", body: formData }
    );
    if (anotherClass) {
      setAnimate(true);
      setTimeout(bannerUp, 4000);
      window.scrollTo({
        top: window.innerHeight * 4,
        left: 0,
        behavior: "smooth",
      });
    }
    return true;
  }

  function submit() {
    if (pushToSheets()) {
      history.push("/submitted");
      console.log(data);
    }
  }

  function onChangeListener(key, value) {
    setData({ ...data, [key]: value });
  }

  useEffect(() => {}, [data]);

  return (
    <>
      <Header title="Waitlist Form" />
      <ReactFullpage
        //fullpage options
        scrollingSpeed={1000} /* Options here */
        autoScrolling={false}
        fitToSection={false}
        licenseKey={"C30CB3E3-6F854137-B837B6F5-B56F03A9"}
        render={({ state, fullpageApi }) => {
          return (
            <>
              <ReactFullpage.Wrapper>
                <Privacy
                  message="Answer these 6 questions to skip some of the waitlist"
                  moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                  buttonMessage="Let's go!"
                />
                <Question
                  title="To start off, what's your name?"
                  label="Full Name"
                  keyName={keys[0]}
                  moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                  onChange={onChangeListener}
                  initial={data[keys[0]]}
                />

                <SelectBar
                  title="What age group do you work with?"
                  label="Age group"
                  keyName={keys[1]}
                  choices={age}
                  moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                  onChange={onChangeListener}
                  initial={data[keys[1]]}
                />

                <SelectBar
                  title="What is your role?"
                  label="Role"
                  keyName={keys[2]}
                  choices={type}
                  moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                  onChange={onChangeListener}
                  initial={data[keys[2]]}
                />

                <Question
                  title="What will you use this site for? (optional)"
                  label="I will use this site for..."
                  keyName={keys[4]}
                  moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                  onChange={onChangeListener}
                  initial={data[keys[4]]}
                />

                <Question
                  title="Any questions? (optional)"
                  label="Seperate questions with a question mark"
                  keyName={keys[5]}
                  moveSectionDown={fullpageApi && fullpageApi.moveSectionDown}
                  onChange={onChangeListener}
                  initial={data[keys[5]]}
                />

                <Question
                  title="What's your email?"
                  label="Email"
                  keyName={keys[6]}
                  moveSectionDown={pushToSheets}
                  onChange={onChangeListener}
                  submitFunction={submit}
                  submit={true}
                  initial={data[keys[6]]}
                />
              </ReactFullpage.Wrapper>
            </>
          );
        }}
      />
    </>
  );
};

export default withRouter(Form);

const age = [
  { value: "Nusery - Kindergarten", label: "Nusery - Kindergarten" },
  { value: "Lower School", label: "Lower School" },
  { value: "Middle School", label: "Middle School" },
  { value: "High School", label: "High School" },
  { value: "College", label: "College" },
];

const type = [
  { value: "Educator", label: "Educator" },
  { value: "Administrator", label: "Administrator" },
  { value: "Tech Support", label: "Tech Support" },
  { value: "Instructional Aide", label: "Instructional Aide" },
];
