import Home from './pages/Home'
import './App.css'
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';

const trackingId = "UA-176116817-7"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

hotjar.initialize('2351907', '6');

function App() {
  return (
    <div>
      <Home />
    </div>
  )
}

export default App
