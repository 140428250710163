import React from 'react'
import Waitlist from './Waitlist'
import '../App.css'

export default function FormPage() {
  return (
    <>
      <div className="App">
        <Waitlist />
      </div>
    </>
  )
}
