import React from "react";
import { Helmet } from "react-helmet";

export default function Header({title, description}) {
  return (
    <>
      <Helmet
        title={title ? `${title} | StudyBuddies` : "StudyBuddies"}
        meta={[
          {
            name: "description",
            content:
              description ? description : "Give your students their best study groups automatically. Used by educators across the country.",
          },
          {
            name: "og:title", 
            content: title ? `${title} | StudyBuddies` : "StudyBuddies",
          },
          {
            name: "og:description",
            content:
            description ? description : "Give your students their best study groups automatically. Used by educators across the country.",
          },
          { name: "og:url", content: "https://try.studybuddies.ai" },
          {
            name: "og:image",
            content: "https://try.studybuddies.ai/socialSharing.jpg",
          },
          {
            name: "twitter:url",
            content: "https://try.studybuddies.ai",
          },
          {
            name: "twitter:title",
            content: title ? `${title} | StudyBuddies` : "StudyBuddies",
          },
          {
            name: "twitter:description",
            content: "Give your students their best study groups... automatically! Used by educators across the country.",
          },
          {
            name: "twitter:image",
            content: "https://try.studybuddies.ai/socialSharing.jpg",
          },
        ]}
      >
        <link rel="canonical" href="https://try.studybuddies.ai" />
      </Helmet>
    </>
  );
}
