import React from 'react'
import Header from '../components/Header'
import styled from 'styled-components'
import { Button } from '../components/stylesComponents' // styles used for shared styles
import ReactGA from 'react-ga'

const Texth4 = styled.h4`
  max-width: 50%;
  @media (max-width: 768px) {
    max-width: 90%;
  }
`

const FormSubmitted = () => {
  return (
    <>
      <Header title="Submitted" />
      <div className="App">
        <h1>Thanks for signing up!</h1>
        <Texth4>
          We're excited to get the product to you as fast as possible
        </Texth4>
        <Button
          href="/"
          onClick={() => {
            ReactGA.event({
              category: 'Navigation',
              action: 'Click',
              label: 'After Form Submitted',
            })
          }}
        >
          Home
        </Button>
      </div>
    </>
  )
}
export default FormSubmitted
