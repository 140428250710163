import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Power } from '../constants'

export const Logo = styled.img`
  max-height: 30vh;
  margin-top: 18px;
`

export const Texth1 = styled.h1`
  font-size: 32px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 32px;
    font-weight: 300;
  }
`

export const Button = styled(Link)`
  border: 4px solid ${Power};
  padding: 4px 8px;
  color: #333;
  font-size: 18px;
  background-color: rgba(0, 0, 0, 0);
  text-decoration: none;
  font-weight: 600;

  transition: all 0.2s;
  &:hover {
    background-color: ${Power};
    color: white;
  }
`

export const List = styled.li`
  font-size: 20px;
  text-align: left;
`

export const Wrapper = styled.div`
  margin: auto;
  max-width: 50%;
  width: 50%;
  @media (max-width: 768px) {
    max-width: 90%;
  }
`

export const Tagline = styled.h1`
  @media (max-width: 796px) {
    font-size: 30px;
    width: 90%;
  }
  width: 60%;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  font-family: Manrope, 'sans-serif';
  font-weight: 800;
  font-size: 60px;
  text-align: center;
  line-height: 80px;
  color: #18191f;
  display: block;
`
