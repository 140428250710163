import styled from "styled-components";
import StatBlock from "../components/StatBlock";
import DirectBlock from "../components/DirectBlock";
import AlgoBlock from "../components/AlgoBlock";
import { Container, Row, Col } from "react-grid-system";
import { Power, GREY_BACKGROUND, FORM_BACKGROUND } from "../constants";
import {
  InputForm,
  Section,
  MyRow,
  MyCol,
} from "../components/stylesComponents";
import "../App.css";
import Navbar from "../components/Navbar";
import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import Testimonial from "../components/Testimonial";
import ReactGA from "react-ga";

const HomeLogo = styled.img`
  max-height: 60vh;
  max-width: 60vw;
  display: flex;
  margin: 40px auto;
  @media (max-width: 768px) {
    width: 96%;
    margin: 20px auto;
  }
`;
const SchoolLogo = styled.img`
  height: 56px;
  display: flex;
  @media (max-width: 768px) {
    margin: 12px;
    height: 36px;
  }
`;

const Subtitle = styled.h1`
  margin: 8px;
  padding: 0;
  color: #555;
  font-size: 18px;
  text-align: center;
`;

const Tagline = styled.h1`
  @media (max-width: 768px) {
    font-size: 36px;
    width: 100%;
    margin-top: 0px;
    line-height: 60px;
    padding: 12px;
    margin-bottom: 0px;
  }
  width: 80%;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  font-family: Manrope, "sans-serif";
  font-weight: 800;
  font-size: 60px;
  text-align: center;
  line-height: 80px;
  color: #18191f;
  display: block;
  margin-bottom: 12px;
`;

const Email = styled.img`
  max-height: 90vh;
  display: flex;
  margin: 40px auto;
  max-width: 58%;
  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const SectionDisappear = styled(Section)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Button = styled.a`
  width: 220px;
  padding: 16px 8px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  background: ${Power};
  border-radius: 8px;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  display: block;
  transition: all 0.5s;
  &:hover {
    background: #6f1fcb;
  }
  @media (max-width: 768px) {
    margin-top: 5%;
  }
`;

const StatsCard = styled.div`
  display: block;
  align-items: center;
  width: 75%;
  margin: 0px auto;
  position: relative;
  top: 50%;
  transform: translate(0%, -50%);
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
  border-radius: 8px;
`;

const StatDisappearCol = styled(Col)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const TestimonialHeading = styled(Tagline)`
  @media (min-width: 768px) {
    font-size: 48px;
  }
`;

const TaglineReady = styled(Tagline)`
  @media (min-width: 768px) {
    font-size: 40px;
  }
`;

const SignupInput = styled(InputForm)`
  width: 320px;
  color: #333;
  border: 1px solid #333;
  margin-top: 2%;
  @media (max-width: 768px) {
    width: 240px;
  }

  @media (max-width: 280px) {
    width: 220px;
  }
`;

function Home() {
  let history = useHistory();
  const [email, setEmail] = useState("");
  function checkEmail() {
    const spamWords = [
      "test",
      "spam",
      "shit",
      "fuck",
      "bitch",
      "cock",
      "cunt",
      "dick",
      "faggot",
      "ass",
      "titty",
      "titties",
    ];
    const specialChars = ["!", "*", "&", "^", "$", "#"];

    if (!email.toLowerCase().includes("@")) {
      alert("Please enter a valid email address");
      return false;
    }

    let tempEmail = email.toLowerCase().trim();
    let before = tempEmail.substr(0, tempEmail.indexOf("@"));

    let spam = false;
    // eslint-disable-next-line
    spamWords.map((word, index) => {
      if (before === word) {
        spam = true;
      }
    });
    // eslint-disable-next-line
    specialChars.map((char, index) => {
      if (tempEmail.includes(char)) {
        spam = true;
      }
    });

    if (tempEmail.split("@").length - 1 > 1) {
      spam = true;
    }
    if (before.includes(" ") || spam || before.length <= 1) {
      alert("Please enter a valid email");
      return false;
    }
    return true;
  }

  function submit() {
    if (checkEmail()) {
      let objEmail = JSON.stringify({ email: email.toLowerCase().trim() });
      fetch(
        `https://m1gfscdpm5.execute-api.us-east-1.amazonaws.com/default/sendWaitlistEmail`,
        { method: "POST", body: objEmail }
      );
      var formData = new FormData();
      formData.append("email", email.toLowerCase().trim());
      fetch(
        "https://script.google.com/macros/s/AKfycbxhHAPSYWVwozf-Ip9MawDFI_9PdfuGdSPcmk7ih0XGMLRqxT67sJ3v4uE3a7k8vqHjJw/exec",
        { method: "POST", body: formData }
      );
      history.push("/emailed");
    }
  }

  return (
    <Fragment>
      <Navbar />
      <Section
        display="flex"
        padding="20px"
        backgroundColor="FCFCF"
        margin="auto"
      >
        <Container>
          <MyRow style={{ margin: "0px", alignItems: "center" }}>
            <MyCol>
              <Tagline>Give your students their best study groups</Tagline>
              <Subtitle>Meant for educators of any grade level</Subtitle>
              <SignupInput
                placeholder="Enter your school email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                onClick={() => {
                  ReactGA.event({
                    category: "Navigation",
                    action: "Click",
                    label: "Type in email",
                  });
                  console.log(email);
                  submit();
                }}
              >
                Sign up for the waitlist
              </Button>
            </MyCol>
          </MyRow>
          <MyRow>
            <HomeLogo src="./main_home_logo.svg" />
          </MyRow>
        </Container>
      </Section>
      <Section>
        <Container>
          <Subtitle>You're in good company</Subtitle>
          <br />
          <MyRow style={{ justifyContent: "space-evenly" }}>
            <MyCol padding="12px">
              <SchoolLogo src="UMich-logo.png" />
            </MyCol>
            <MyCol padding="12px">
              <SchoolLogo src="UCLA-logo.png" />
            </MyCol>
            <MyCol padding="12px">
              <SchoolLogo src="UChicago-logo.png" />
            </MyCol>
          </MyRow>
        </Container>
        <br />
        <br />
      </Section>
      <Section padding="0px" backgroundColor="#fafafa">
        <MyRow>
          <MyCol xl={3}>
            <DirectBlock
              numberText="1"
              beginText="Customize your class groups"
              imageText="./community.svg"
            />
          </MyCol>
          <MyCol xl={3}>
            <DirectBlock
              numberText="2"
              beginText="Send students link to a questionnaire we generate"
              imageText="./fill_forms.svg"
            />
          </MyCol>
          <MyCol xl={3}>
            <DirectBlock
              numberText="3"
              beginText="You’re done! We’ll email your students their curated groups"
              imageText="./mail_sent.svg"
              endText=""
            />
          </MyCol>
        </MyRow>
        <br />
        <br />
      </Section>
      <SectionDisappear padding="0px" backgroundColor={GREY_BACKGROUND}>
        <Container>
          <MyRow style={{ margin: "0px" }}>
            <TestimonialHeading>
              The email we send your students
            </TestimonialHeading>
            <Email src="./email.svg" />
          </MyRow>
        </Container>
      </SectionDisappear>
      <Section padding="0px">
        <TestimonialHeading>How our algorithm works</TestimonialHeading>
        <MyRow>
          <MyCol xl={1} padding="12px">
            <AlgoBlock
              numberText="Ask."
              imageText="./questionMark.svg"
              beginText="We ask your students about their study habits, club affiliations, and interests"
            />
          </MyCol>
          <MyCol xl={1} padding="12px">
            <AlgoBlock
              numberText="Calculate."
              imageText="./calculator.svg"
              beginText="We'll compare every students' responses to every other students' to ensure the group will work well together"
            />
          </MyCol>
          <MyCol xl={1} padding="12px">
            <AlgoBlock
              numberText="Introduce."
              imageText="./intro.svg"
              beginText="Once we find the optimal groups, we'll introduce them in a way that encourages collaboration"
            />
          </MyCol>
        </MyRow>
      </Section>

      <Section
        style={{ backgroundImage: `linear-gradient(${FORM_BACKGROUND}, #fff)` }}
      >
        <br />
        <TestimonialHeading>Don't just take it from us</TestimonialHeading>
        <Container>
          <MyRow style={{ justifyContent: "space-evenly" }}>
            <MyCol padding="12px">
              <Testimonial
                text="It's been an awesome way to get clarification on course material and make friends"
                name="Nitya Nakirekanti"
                position="Student at UMich"
              />
            </MyCol>
            <MyCol padding="12px">
              <Testimonial
                text="This website alleviated all of the work of creating study groups in the fall quarter, for which I truly appreciate!"
                name="Kotaro Yoshida"
                position="Professor at UChicago"
              />
            </MyCol>
            <MyCol padding="12px">
              <Testimonial
                text="I used StudyBuddies to get through my Chemistry class and I thought they matched me really well with the people I met through it"
                name="Elliot Berdy"
                position="Student at UCLA"
              />
            </MyCol>
          </MyRow>
        </Container>
        <br />
        <br />
        <br />
        <Container>
          <Row>
            <StatDisappearCol xl={3} lg={6}>
              <StatBlock number="30+" descr="University Dept. Endorsements" />
            </StatDisappearCol>
            <Col xl={3} lg={6}>
              <StatBlock number="8,000+" descr="Student Groups Created" />
            </Col>
            <Col xl={3} lg={6}>
              <StatBlock number="23,000+" descr="Students Connected" />
            </Col>
            <StatDisappearCol xl={3} lg={6}>
              <StatBlock number="100,000+" descr="Academic Messages Sent" />
            </StatDisappearCol>
          </Row>
        </Container>
      </Section>
      <Section style={{ textAlign: "center" }}>
        <TaglineReady>Ready to get started?</TaglineReady>
        <SignupInput
          placeholder="Enter your school email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          onClick={() => {
            ReactGA.event({
              category: "Navigation",
              action: "Click",
              label: "Type in email",
            });
            console.log(email);
            submit();
          }}
        >
          Join the waitlist
        </Button>
        <br />
        <br />
        <br />
      </Section>
    </Fragment>
  );
}

export default Home;
