import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import Home from './pages/Home'
import Waitlist from './pages/WaitlistPage'
import reportWebVitals from './reportWebVitals'
import PrivacyPol from './pages/PrivacyPol'
import EmailSubmitted from './pages/EmailSubmitted'
import FormSubmitted from './pages/FormSubmitted'

const routing = (
  <Router>
    <Switch>
      <Route exact path="/" component={App} />
      <Route path="/home" component={Home} />
      <Route path="/emailed" component={EmailSubmitted} />
      <Route path="/submitted" component={FormSubmitted} />
      <Route path="/privacy" component={PrivacyPol} />
      <Route path="/waitlist" component={Waitlist} />
    </Switch>
  </Router>
)
ReactDOM.render(routing, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
