import React from 'react'
import styled from 'styled-components'
import { Wrapper, Texth1, List, Button } from './styles'

export const Logo = styled.img`
  max-height: 30vh;
  max-width: 30vw;
  margin-top: 18px;
`

export default function PrivacyPol() {
  return (
    <>
      <div className="App" style={{ padding: '0px', 'text-align': 'center' }}>
        <Wrapper>
          <br />
          <Logo src="/main_home_logo.svg" />
          <br />
          <Texth1>What happens to the data?</Texth1>
          <br />
          <ol>
            <List>
              StudyBuddies will not sell any personally identifiable data
              collected from the students of any accredited college or
              university.
            </List>
            <br />
            <List>
              StudyBuddies will not give an accredited college or university
              student's contact information to another party outside that
              accredited college or university unless the student has explicitly
              consented to do so.
            </List>
            <br />
            <List>
              StudyBuddies has full ownership of the data that it collects from
              students of any accredited college or university subject to the
              conditions above.
            </List>
          </ol>
          <br />

          <br />
          <Button to="/">Back</Button>
          <br />
          <br />
        </Wrapper>
      </div>
    </>
  )
}
